<template>
    <div class="friendImg">
        <!-- 背景图 -->
<!--        <img class="headBack" src="http://img.fuqz.cc/photo-head.jpg" alt="">-->
        <div class="head_title">
            <span>{{text}}</span><br/><br/><br/>
            <span>{{producre}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "photoImg",
        data(){
            return{
                /* 标题 */
                text:'照片墙',
                producre:'记录美好瞬间'
            }
        },
    }
</script>

<style scoped>

</style>
